import React, { useEffect, useState, useRef } from "react";
import SmallTitle from "components/title/SmallTitle";
import Flex from "components/flex/Flex";
import styled from "styled-components";
import withProgressBar from "hoc/withProgressBar";
import fonts from "libs/fonts";
import * as APIS from "libs/apis";
import GridTable, {
  CenterCell,
  NumberCell,
} from "components/gird-table/GridTable";
import TextInput from "components/input/TextInput";
import { GridColumn } from "@progress/kendo-react-grid";
import DropDownList from "components/select/DropDownList";
import StyledTable from "components/table/StyledTable";
import SearchLayout, { SearchItem } from "layouts/search/SearchLayout";
import DateInput from "components/input/DateInput";
import { numberWithCommas, formatTime } from "libs/utils";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { objToQueryStr } from "services/utils";
import { actionOpen, actionError } from "redux/action/ActionActions";
import { useDispatch } from "react-redux";
import KendoButton from "components/button/KendoButton";
import cloneDeep from "lodash.clonedeep";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import { closeModal, openModal } from "redux/modal/ModalReducer";
import { makeStyles } from "@material-ui/core";
import { Popup } from "@progress/kendo-react-popup";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import StockStatus from "../components/StockStatus";
// import "@progress/kendo-react-intl/dist/es/all";
const POPUP_WIDTH = 500;

const assetRadioData = [
  {
    label: "금",
    value: "GOLD",
  },
  {
    label: "은",
    value: "SILVER",
  },
];

const sortRadioData = [
  {
    label: "최근 순서로",
    value: "DESC",
  },
  {
    label: "오래된 순서로",
    value: "ASC",
  },
];
const SELECTED_FIELD = "selected";

const INIT_STATE = {
  dateType: "STOCKED",
  startDate: "",
  endDate: "",
  assetType: "GOLD",
  purityType: "",
  refineStatus: "",
  page: 0,
  size: 10,
  total: 0,
  sort: "DESC",
};

const RECEIVING_INIT_STATE = {
  dateType: "DELIVERED",
  startDate: "",
  endDate: "",
  assetType: "GOLD",
  purityType: "",
  refineStatus: "",
  page: 0,
  size: 10,
  total: 0,
  sort: "DESC",
};

const InventoryRefine = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [searchState, setSearchState] = useState({ ...INIT_STATE });
  const [receivingSearchState, setReceivingSearchState] = useState({
    ...RECEIVING_INIT_STATE,
  });
  const [refineOutList, setRefineOutList] = useState([]);
  const [refineInList, setRefineInList] = useState([]);
  const [checkList, setCheckList] = useState([]); //정련 출고 체크리스트
  const [purityTypeGoldList, setPurityTypeGoldList] = useState([]);
  const [purityTypeSilverList, setPurityTypeSilverList] = useState([]);

  useEffect(() => {
    APIS.getPurityType().then(({ data: { success, data, message } }) => {
      if (success) {
        setPurityTypeGoldList(
          data
            .find((item) => item.assetType === "GOLD")
            .purityTypeList.map(({ purityType, purityTypeName }) => {
              return { key: purityType, text: purityTypeName };
            })
        );
        setPurityTypeSilverList(
          data
            .find((item) => item.assetType === "SILVER")
            .purityTypeList.map(({ purityType, purityTypeName }) => {
              return { key: purityType, text: purityTypeName };
            })
        );
      } else {
        dispatch(actionError(message));
      }
    });
  }, []);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleChangeSearchValue = (value = {}) => {
    setSearchState({ ...searchState, ...value });
  };

  const handleChangeReceiveValue = (value = {}) => {
    setReceivingSearchState({ ...receivingSearchState, ...value });
  };

  const comma = (obj, maxNum) => {
    //소수점 2자리수까지 입력 받기
    var bExists = obj.indexOf(".", 0); //0번째부터 .을 찾는다.
    var strArr = obj.split(".");
    if (maxNum) {
      const maxNumber = maxNum;
      if (strArr[0] > maxNumber) {
        strArr[0] = maxNumber;
      }
    }
    if (String(strArr[0]).substring(0, 1) === 0) {
      strArr[0] = (strArr[0] + "").substring(1, String(strArr[0]).length);
    }
    if (strArr[0] === "") {
      strArr[0] = 0;
    }
    if (String(strArr[1]).length > 2) {
      strArr[1] = (strArr[1] + "").substring(0, 2);
    }

    if (bExists > -1) {
      obj = Number(strArr[0]) + "." + strArr[1];
    } else {
      obj = Number(strArr[0]);
    }
    return obj; //문자열 반환
  };

  return (
    <InventoryStatusContainer>
      <SmallTitle>현황 재고</SmallTitle>
      <RefineStatus>
        <StockStatus refresh={refresh} />
      </RefineStatus>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title="정련 출고 관리">
          <StatusGrid />
          <SearchSection
            setCheckList={setCheckList}
            setRefresh={setRefresh}
            searchState={searchState}
            setSearchState={setSearchState}
            handleChangeSearchValue={handleChangeSearchValue}
            setRefineOutList={setRefineOutList}
            purityTypeGoldList={purityTypeGoldList}
            purityTypeSilverList={purityTypeSilverList}
          />
          <SmallTitle style={{ marginTop: 20 }}>
            정련출고내역 및 요청
          </SmallTitle>
          <RefineSection
            checkList={checkList}
            setRefresh={setRefresh}
            setCheckList={setCheckList}
            searchState={searchState}
            handleChangeSearchValue={handleChangeSearchValue}
            refineOutList={refineOutList}
            setRefineOutList={setRefineOutList}
            purityTypeGoldList={purityTypeGoldList}
            purityTypeSilverList={purityTypeSilverList}
          />
        </TabStripTab>
        <TabStripTab title="정련 입고 관리">
          <ReceivingSearchSection
            setRefresh={setRefresh}
            receivingSearchState={receivingSearchState}
            setReceivingSearchState={setReceivingSearchState}
            handleChangeReceiveValue={handleChangeReceiveValue}
            setRefineInList={setRefineInList}
            purityTypeGoldList={purityTypeGoldList}
            purityTypeSilverList={purityTypeSilverList}
          />
          <SmallTitle style={{ marginTop: 20 }}>
            정련입고내역 및 요청
          </SmallTitle>
          <ReceivingRefineSection
            receivingSearchState={receivingSearchState}
            handleChangeReceiveValue={handleChangeReceiveValue}
            refineInList={refineInList}
            setRefineInList={setRefineInList}
            comma={comma}
          />
        </TabStripTab>
      </TabStrip>
    </InventoryStatusContainer>
  );
};
const StatusGrid = withProgressBar(({ progressBar }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState("GOLD");
  const [waitList, setWaitList] = useState([]);

  const handleChange = (e) => {
    setChecked(e.value);
  };
  useEffect(() => {
    progressBar.on();
    const req = {
      assetType: checked,
    };
    APIS.getInventoryRefineWait(objToQueryStr(req))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setWaitList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => progressBar.off());
  }, [checked]);

  return (
    <Flex>
      <Flex row style={{ alignItems: "center" }}>
        <SmallTitle>정련대기현황</SmallTitle>
        <RadioGroup
          data={assetRadioData}
          layout={"horizontal"}
          style={{ marginLeft: 40 }}
          value={checked}
          onChange={handleChange}
        />
      </Flex>
      <StyledTable row style={{ marginBottom: 10 }}>
        <Flex>
          <Flex style={{ width: 100 }}>
            <Flex className="header">카테고리</Flex>
            <Flex className="data">
              {checked === "GOLD" ? "금(gold)" : "은(silver)"}
            </Flex>
          </Flex>
        </Flex>
        {waitList.map((item, i) => (
          <Flex key={i.toString()}>
            <Flex className="header">{item.description}</Flex>
            <Flex className="data">{numberWithCommas(item.gram)}</Flex>
          </Flex>
        ))}
        <Flex>
          <Flex className="header">합계</Flex>
          <Flex className="data">
            {numberWithCommas(
              Number(
                waitList.reduce((acc, item) => acc + item.gram, 0)
              ).toFixed(3)
            )}
          </Flex>
        </Flex>
      </StyledTable>
    </Flex>
  );
});

const SearchSection = withProgressBar(
  ({
    setCheckList,
    setRefresh,
    searchState,
    progressBar,
    setSearchState,
    handleChangeSearchValue,
    setRefineOutList,
    purityTypeGoldList,
    purityTypeSilverList,
  }) => {
    const dispatch = useDispatch();
    const { assetType } = searchState;
    const [purityTypeList, setPurityTypeList] = useState(
      assetType === "GOLD" ? purityTypeGoldList : purityTypeSilverList
    );
    useEffect(() => {
      handleChangeSearchValue({ purityType: "" });
      setPurityTypeList(
        assetType === "GOLD" ? purityTypeGoldList : purityTypeSilverList
      );
    }, [assetType, purityTypeGoldList, purityTypeSilverList]);

    const handleClickSearch = () => {
      searchRefineOutList({ ...searchState });
      setCheckList([]);
    };

    const searchRefineOutList = (param) => {
      setRefresh(true);
      APIS.getInventoryRefineOut(objToQueryStr(param))
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setRefineOutList(data.content);
            //리스트 넣는 함수 넣어야한다.
            handleChangeSearchValue({
              page: data.number,
              total: data.totalElements,
            });
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => {
          setRefresh(false);
        });
    };
    return (
      <SearchLayout
        title="정련 출고 관리"
        onSearch={() => {
          handleClickSearch();
        }}
        onReset={() => {
          setSearchState({ ...INIT_STATE });
        }}>
        <Flex row>
          <SearchItem header="조회기간">
            <DropDownList
              options={[
                { label: "접수일", value: "STOCKED" },
                { label: "출고완료일", value: "DELIVERED" },
              ]}
              selected={searchState.dateType}
              onChange={(value) => handleChangeSearchValue({ dateType: value })}
              style={{ width: 120, marginRight: 10 }}
            />
            <DateInput
              onChange={(value) =>
                handleChangeSearchValue({ startDate: value })
              }
              value={searchState.startDate}
              max={formatTime()}
            />
            ~{" "}
            <DateInput
              onChange={(value) => handleChangeSearchValue({ endDate: value })}
              value={searchState.endDate}
              min={searchState.startDate}
            />
          </SearchItem>
          <SearchItem header="자산 / 품목">
            <Flex row>
              <DropDownList
                options={[
                  { label: "금", value: "GOLD" },
                  { label: "은", value: "SILVER" },
                ]}
                selected={searchState.assetType}
                onChange={(value) =>
                  handleChangeSearchValue({ assetType: value })
                }
                style={{ width: 80, marginRight: 10 }}
              />
              <DropDownList
                options={purityTypeList.map(({ key, text }) => ({
                  label: text,
                  value: key,
                }))}
                selected={searchState.purityType}
                onChange={(value) =>
                  handleChangeSearchValue({ purityType: value })
                }
                useAll
                style={{ width: 350 }}
              />
            </Flex>
          </SearchItem>
          <SearchItem header="상태">
            <DropDownList
              options={[
                { label: "출고대기", value: "TAKE_WAIT" },
                { label: "출고완료", value: "TAKE_COMPLETE" },
              ]}
              selected={searchState.refineStatus}
              onChange={(value) =>
                handleChangeSearchValue({ refineStatus: value })
              }
              useAll
              style={{ width: 120, marginRight: 10 }}
            />
          </SearchItem>
        </Flex>
      </SearchLayout>
    );
  }
);

const ReceivingSearchSection = withProgressBar(
  ({
    setRefresh,
    receivingSearchState,
    setReceivingSearchState,
    handleChangeReceiveValue,
    setRefineInList,
    purityTypeGoldList,
    purityTypeSilverList,
  }) => {
    const dispatch = useDispatch();
    const { assetType } = receivingSearchState;
    const [purityTypeList, setPurityTypeList] = useState(
      assetType === "GOLD" ? purityTypeGoldList : purityTypeSilverList
    );

    useEffect(() => {
      handleChangeReceiveValue({ purityType: "" });
      setPurityTypeList(
        assetType === "GOLD" ? purityTypeGoldList : purityTypeSilverList
      );
    }, [assetType]);

    const handleClickSearch = () => {
      searchRefineInList({ ...receivingSearchState });
    };

    const searchRefineInList = (param) => {
      setRefresh(true);
      APIS.getInventoryRefineIn(objToQueryStr(param))
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setRefineInList(data.content);
            handleChangeReceiveValue({
              page: data.number,
              total: data.totalElements,
            });
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => {
          setRefresh(false);
        });
    };
    return (
      <SearchLayout
        title="정련 입고 관리"
        onSearch={() => {
          handleClickSearch();
        }}
        onReset={() => {
          setReceivingSearchState(RECEIVING_INIT_STATE);
        }}>
        <Flex row>
          <SearchItem header="조회기간">
            <DropDownList
              options={[
                { label: "출고날짜", value: "DELIVERED" },
                { label: "입고날짜", value: "STOCKED" },
              ]}
              selected={receivingSearchState.dateType}
              onChange={(value) =>
                handleChangeReceiveValue({ dateType: value })
              }
              style={{ width: 120, marginRight: 10 }}
            />
            <DateInput
              onChange={(value) =>
                handleChangeReceiveValue({ startDate: value })
              }
              value={receivingSearchState.startDate}
              max={formatTime()}
            />
            ~{" "}
            <DateInput
              onChange={(value) => handleChangeReceiveValue({ endDate: value })}
              value={receivingSearchState.endDate}
              min={receivingSearchState.startDate}
            />
          </SearchItem>
          <SearchItem header="자산 / 품목">
            <Flex row>
              <DropDownList
                options={[
                  { label: "금", value: "GOLD" },
                  { label: "은", value: "SILVER" },
                ]}
                selected={receivingSearchState.assetType}
                onChange={(value) =>
                  handleChangeReceiveValue({ assetType: value })
                }
                style={{ width: 80, marginRight: 10 }}
              />
              <DropDownList
                options={purityTypeList.map(({ key, text }) => ({
                  label: text,
                  value: key,
                }))}
                selected={receivingSearchState.purityType}
                onChange={(value) =>
                  handleChangeReceiveValue({ purityType: value })
                }
                useAll
                style={{ width: 350 }}
              />
            </Flex>
          </SearchItem>
          <SearchItem header="상태">
            <DropDownList
              options={[
                { label: "입고대기", value: "STOCKED_WAIT" },
                { label: "부분입고", value: "STOCKED_PART" },
                { label: "입고완료", value: "STOCKED_COMPLETE" },
              ]}
              selected={receivingSearchState.refineStatus}
              onChange={(value) =>
                handleChangeReceiveValue({ refineStatus: value })
              }
              useAll
              style={{ width: 120, marginRight: 10 }}
            />
          </SearchItem>
        </Flex>
      </SearchLayout>
    );
  }
);
const RefineSection = withProgressBar(
  ({
    checkList,
    setRefresh,
    setCheckList,
    searchState,
    progressBar,
    handleChangeSearchValue,
    refineOutList,
    setRefineOutList,
    purityTypeGoldList,
    purityTypeSilverList,
  }) => {
    const dispatch = useDispatch();
    const [selectedSummary, setSelectedSummary] = useState([]);
    useEffect(() => {
      fetchList();
    }, [searchState.sort, searchState.page, searchState.size]);

    const fetchList = () => {
      setRefresh(true);
      progressBar.on();
      APIS.getInventoryRefineOut(objToQueryStr({ ...searchState }))
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setRefineOutList(data.content);
            handleChangeSearchValue({
              page: data.number,
              total: data.totalElements,
            });
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => {
          setRefresh(false);
          progressBar.off();
        });
    };

    useEffect(() => {
      // const sum = {};
      // const result = [];
      // checkList.forEach(({ purityType, inspectWeightGram, stockedPath }) => {
      //   const numberValue = (inspectWeightGram + "").replace(/[^0-9.]/g, "");
      //   sum[purityType] = Number(
      //     ((sum[purityType] || 0) + +numberValue).toFixed(4)
      //   );
      // });
      // for (const type in sum) {
      //   result.push({
      //     label: type,
      //     value: sum[type],
      //     outMaxRate: checkList.find((item) => item.purityType === type)
      //       .outMaxRate,
      //     outMinRate: checkList.find((item) => item.purityType === type)
      //       .outMinRate,
      //   });
      // }
      // setSelectedSummary(result);
      const sum = {};
      const result = [];
      checkList.forEach(({ purityType, inspectWeightGram, stockedPath }) => {
        const numberValue = (inspectWeightGram + "").replace(/[^0-9.]/g, "");
        // purityType과 stockedPath의 조합을 키로 사용
        const key =
          stockedPath !== null
            ? `${purityType}(${stockedPath})`
            : `${purityType}`;
        sum[key] = sum[key] || { purityType, stockedPath, totalWeight: 0 };
        sum[key].totalWeight += Number((+numberValue).toFixed(4));
      });

      for (const key in sum) {
        const { purityType, stockedPath, totalWeight } = sum[key];
        result.push({
          label: key,
          value: totalWeight,
          outMaxRate: checkList.find(
            (item) =>
              item.purityType === purityType && item.stockedPath === stockedPath
          )?.outMaxRate,
          outMinRate: checkList.find(
            (item) =>
              item.purityType === purityType && item.stockedPath === stockedPath
          )?.outMinRate,
          purityType,
          stockedPath,
        });
      }
      setSelectedSummary(result);
    }, [checkList]);

    const onPageChange = (event) => {
      const { page: pageData } = event;
      const { skip, take } = pageData;
      handleChangeSearchValue({ page: skip / take, size: take });
    };

    return (
      <InventoryRefineContainer>
        <Flex row style={{ justifyContent: "space-between" }}>
          <RadioGroup
            data={sortRadioData}
            layout={"horizontal"}
            value={searchState.sort}
            onChange={({ value }) => handleChangeSearchValue({ sort: value })}
          />
          <Flex row>
            <span className="title">선택된 중량합계</span>
            <StyledTable className="summary">
              <Flex row>
                {selectedSummary.map(({ label, value }) => {
                  return (
                    <Flex row>
                      <Flex className="header">{label}</Flex>
                      <Flex className="data">{value}</Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </StyledTable>
            <KendoButton
              style={{ marginLeft: 10 }}
              onClick={() => {
                checkList.length === 0
                  ? dispatch(actionError("선택된 정련내역이 없습니다."))
                  : dispatch(
                      openModal({
                        visiable: true,
                        children: (
                          <RefineModal
                            checkList={checkList}
                            setCheckList={setCheckList}
                            fetchList={fetchList}
                            selectedSummary={selectedSummary}
                            searchState={searchState}
                            purityTypeGoldList={purityTypeGoldList}
                            purityTypeSilverList={purityTypeSilverList}
                          />
                        ),
                      })
                    );
              }}>
              정련요청
            </KendoButton>
          </Flex>
        </Flex>
        <GridTable
          className="grid"
          name={"InventoryRefineOut"}
          reorderable
          data={refineOutList}
          selectedField={SELECTED_FIELD}
          skip={searchState.page * searchState.size}
          take={searchState.size}
          total={searchState.total}
          pageSizes={[10, 25, 50, 100, 400]}
          pageable
          onPageChange={onPageChange}
          style={{ height: 475 }}
          scrollable
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
          }}>
          <GridColumn className="td-c" title="접수날짜" field={"stockedDate"} />
          <GridColumn className="td-c" title="품목" field={"purityType"} />
          <GridColumn
            title="입고경로"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">{`${dataItem["stockedPath"] || "-"}`}</td>
              );
            }}
          />
          <GridColumn
            title="감정환산중량"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">{`${
                  numberWithCommas(dataItem["inspectWeightGram"]) || "-"
                }`}</td>
              );
            }}
          />
          <GridColumn
            className="td-c"
            title="출고상태"
            field={"refineStatus"}
          />
          <GridColumn
            title="출고완료일"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">{`${
                  formatTime(dataItem["deliveryAt"]) || "-"
                }`}</td>
              );
            }}
          />
          <GridColumn
            title=""
            field={SELECTED_FIELD}
            headerCell={() => {
              return (
                <div style={{ textAlign: "center" }}>
                  <Checkbox
                    checked={
                      refineOutList.filter((item) => !item.deliveryAt)
                        .length === checkList.length &&
                      refineOutList.length !== 0
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const { ariaChecked } = e.syntheticEvent.target;
                      if (ariaChecked === "true") {
                        setCheckList([]);
                      } else {
                        setCheckList([
                          ...refineOutList.filter((item) => !item.deliveryAt),
                        ]);
                      }
                    }}
                  />
                </div>
              );
            }}
            cell={(props) => {
              const { dataItem } = props;
              const data = dataItem["id"];
              const idList = checkList.map((item) => item.id);
              return (
                <td style={{ textAlign: "center" }}>
                  {!dataItem["deliveryAt"] && (
                    <Checkbox
                      checked={idList.includes(data) ? true : false}
                      onChange={() => {
                        let parseList = cloneDeep(checkList);
                        if (idList.includes(data)) {
                          const result = parseList.filter((item) => {
                            item.id !== data;
                          });
                          setCheckList(result);
                        } else {
                          parseList.push(dataItem);
                          setCheckList(parseList);
                        }
                      }}
                    />
                  )}
                </td>
              );
            }}
          />
        </GridTable>
      </InventoryRefineContainer>
    );
  }
);

const ReceivingRefineSection = withProgressBar(
  ({
    receivingSearchState,
    progressBar,
    handleChangeReceiveValue,
    refineInList,
    setRefineInList,
    comma,
  }) => {
    const dispatch = useDispatch();
    const [popupState, setPopupState] = useState({
      show: false,
      offset: { top: 0, left: 0 },
    });

    useEffect(() => {
      fetchList();
    }, [
      receivingSearchState.sort,
      receivingSearchState.page,
      receivingSearchState.size,
    ]);

    const fetchList = () => {
      progressBar.on();
      APIS.getInventoryRefineIn(objToQueryStr({ ...receivingSearchState }))
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setRefineInList(data.content);
            handleChangeReceiveValue({
              page: data.number,
              total: data.totalElements,
            });
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    };

    const onPageChange = (event) => {
      const { page: pageData } = event;
      const { skip, take } = pageData;
      handleChangeReceiveValue({ page: skip / take, size: take });
    };

    const handlePopupShow = ({ target, item, field }) => {
      const rect = target.getBoundingClientRect();
      const top = window.pageYOffset + rect.bottom + 5;

      const left =
        window.pageXOffset + (rect.left + rect.right) / 2 - POPUP_WIDTH / 2;

      setPopupState({
        show: true,
        offset: { left, top },
        item,
        field,
      });
    };

    const handlePopupClose = () => {
      setPopupState({ ...popupState, show: false });
    };

    return (
      <InventoryRefineContainer>
        <Flex row style={{ justifyContent: "space-between" }}>
          <RadioGroup
            data={sortRadioData}
            layout={"horizontal"}
            value={receivingSearchState.sort}
            onChange={({ value }) => handleChangeReceiveValue({ sort: value })}
          />
          <KendoButton
            onClick={() => {
              dispatch(
                openModal({
                  visiable: true,
                  children: <EtcRefineModal comma={comma} />,
                })
              );
            }}>
            기타입고
          </KendoButton>
        </Flex>
        <GridTable
          className="grid"
          name={"InventoryRefineIn"}
          reorderable
          data={refineInList}
          skip={receivingSearchState.page * receivingSearchState.size}
          take={receivingSearchState.size}
          total={receivingSearchState.total}
          pageable
          onPageChange={onPageChange}
          style={{ height: 575 }}
          scrollable
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
          }}>
          <GridColumn
            className="td-c"
            title="출고날짜"
            field={"deliveredDate"}
            width={120}
          />
          <GridColumn
            className="td-c"
            title="품목"
            field={"purityType"}
            width={120}
          />
          <GridColumn
            title="실중량"
            width={95}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["inputWeightGram"]
                    ? numberWithCommas(dataItem["inputWeightGram"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="감정환산중량(고객)"
            width={130}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["inspectWeightGram"]
                    ? numberWithCommas(dataItem["inspectWeightGram"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="환산율(%)"
            width={90}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">{dataItem["exchangeRate"] || "-"}</td>
              );
            }}
          />
          <GridColumn
            title="전해양극중량"
            width={98}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["electrolyticPolarGram"]
                    ? numberWithCommas(dataItem["electrolyticPolarGram"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="순도(%)"
            width={90}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">{dataItem["inputPurityPct"] || "-"}</td>
              );
            }}
          />
          <GridColumn
            title="평가값"
            width={90}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["evaluationValue"]
                    ? numberWithCommas(dataItem["evaluationValue"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="3M책임중량"
            width={90}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["chargeWeightGram"]
                    ? numberWithCommas(dataItem["chargeWeightGram"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="코다입고날짜"
            width={120}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {dataItem["stockedAt"]
                    ? formatTime(dataItem["stockedAt"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            field="stockedWeightGram"
            width={95}
            title="입고"
            cell={(props) => {
              const { dataItem, field } = props;
              if (!dataItem[field]) {
                return <NumberCell {...props} />;
              }
              return (
                <NumberCell
                  {...props}
                  className="click-cell"
                  onClick={({ target }) => {
                    handlePopupShow({
                      target,
                      item: props.dataItem,
                      field: props.field,
                    });
                  }}
                />
              );
            }}
          />
          <GridColumn
            title="입고상태"
            width={80}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">{dataItem["stockedStatus"] || "-"}</td>
              );
            }}
          />
          <GridColumn
            title="최종회수율"
            width={90}
            cell={(props) => {
              const { dataItem } = props;
              return <td className="td-r">{dataItem["returnRate"] || "-"}</td>;
            }}
          />
          <GridColumn
            title="최종잉여잔량"
            width={120}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-r">
                  {dataItem["balanceWeightGram"]
                    ? numberWithCommas(dataItem["balanceWeightGram"])
                    : "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title=""
            width={220}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {dataItem.stockedStatus !== "입고완료" ? (
                    <KendoButton
                      onClick={() => {
                        dispatch(
                          openModal({
                            visiable: true,
                            children: (
                              <ReceivingRefineModal
                                id={dataItem.id}
                                fetchList={fetchList}
                                comma={comma}
                                list={refineInList}
                              />
                            ),
                          })
                        );
                      }}>
                      입고
                    </KendoButton>
                  ) : (
                    <KendoButton
                      onClick={() => {
                        dispatch(
                          openModal({
                            visiable: true,
                            children: (
                              <ReceivingRefineModify
                                id={dataItem.id}
                                fetchList={fetchList}
                                list={refineInList}
                              />
                            ),
                          })
                        );
                      }}>
                      입고 수정
                    </KendoButton>
                  )}
                  {dataItem.purityType === "24K" &&
                    dataItem.stockedStatus === "입고대기" && (
                      <KendoButton
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          dispatch(
                            openModal({
                              visiable: true,
                              children: (
                                <ReceivingRefineChargeModal
                                  id={dataItem.id}
                                  fetchList={fetchList}
                                  comma={comma}
                                  // maxNumber={dataItem.inputWeightGram}
                                />
                              ),
                            })
                          );
                        }}>
                        책임중량
                      </KendoButton>
                    )}
                </td>
              );
            }}
          />
        </GridTable>
        <RefineHistoryPopup {...popupState} onClose={handlePopupClose} />
      </InventoryRefineContainer>
    );
  }
);
const RefineHistoryPopup = ({ show, offset, onClose, item = {} }) => {
  const popupRef = useRef(null);
  const { historyDtoList } = item;
  return (
    <Popup
      show={show}
      offset={offset}
      style={{ width: POPUP_WIDTH, zIndex: "1" }}
      onOpen={() => {
        popupRef.current.focus();
      }}>
      <PopupContainer ref={popupRef} tabIndex={0} onBlur={() => {}}>
        <Flex className="title">입고내역</Flex>
        <Flex className="content">
          <GridTable
            className="grid"
            data={historyDtoList}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
            }}>
            <GridColumn
              title="날짜"
              width={140}
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td className="td-c">
                    {formatTime(dataItem["stockedAt"]) || "-"}
                  </td>
                );
              }}
            />
            <GridColumn
              title="입고중량"
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td className="td-c">
                    {numberWithCommas(dataItem["stockedWeightGram"])}
                  </td>
                );
              }}
            />
            <GridColumn
              title="입고상태"
              cell={(props) => {
                const { dataItem } = props;
                return <td className="td-c">{dataItem["stockedStatus"]}</td>;
              }}
            />
            <GridColumn
              title="관리자 메모"
              cell={(props) => {
                const { dataItem } = props;
                return dataItem["managerMemo"] ? (
                  <td>{dataItem["managerMemo"]}</td>
                ) : (
                  <td className="td-c">-</td>
                );
              }}
            />
            <GridColumn
              title="관리자"
              cell={(props) => {
                const { dataItem } = props;
                return <td className="td-c">{dataItem["managerName"]}</td>;
              }}
            />
          </GridTable>
          <Flex row style={{ justifyContent: "center" }}>
            <KendoButton label="확인" onClick={onClose} />
          </Flex>
        </Flex>
      </PopupContainer>
    </Popup>
  );
};

const RefineModal = withProgressBar(
  ({
    progressBar,
    checkList,
    setCheckList,
    fetchList,
    selectedSummary,
    searchState,
    purityTypeGoldList,
    purityTypeSilverList,
  }) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const { assetType } = searchState;
    const [refineList, setRefineList] = useState([
      ...selectedSummary.map((item) => {
        return { ...item, weightGram: 0 };
      }),
    ]);
    const purityTypeList =
      assetType === "GOLD" ? purityTypeGoldList : purityTypeSilverList;

    const handleClickSave = (adminPassword) => {
      const data = {};
      const refineOutList = refineList.map((item) => {
        const idList = checkList.filter(
          (checkItem) =>
            item.purityType === checkItem.purityType &&
            item.stockedPath === checkItem.stockedPath
        );
        return {
          assetType: checkList[0].assetType
            ?.replace("금", "GOLD")
            ?.replace("은", "SILVER"),
          purityType: purityTypeList.find(
            (purityType) => purityType.text === item.purityType
          ).key,
          input: item.weightGram, // 총 실중량
          inspectWeightGram: item.value, // 총 감정실중량
          ids: idList.map((item) => item.id),
          stockedPath: item.stockedPath
            ?.replace("재질분류", "MATERIALS")
            ?.replace("제품회수", "GOODS_RETRIEVE"),
        };
      });
      data["refineOutDtoList"] = refineOutList;
      data["managerPassword"] = adminPassword;

      const errArr = refineOutList.find(
        (item) => item.input === 0 || item.input === null
      );
      if (errArr) {
        dispatch(actionError("총 실중량 값을 입력하여 주세요."));
      } else {
        progressBar.on();
        APIS.postInventoryRefineOut(data)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "저장이 완료되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
              dispatch(closeModal());
              setCheckList([]);
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(progressBar.off);
      }
    };

    return (
      <div>
        <StyledTable>
          <Flex row>
            {refineList?.map((item, index) => {
              const weightGram = item.weightGram;
              const [value, setValue] = useState(weightGram);
              useEffect(() => {
                setValue(weightGram);
              }, [weightGram]);
              let rate =
                Number(value) !== 0
                  ? Number((item.value / Number(value)) * 100).toFixed(2)
                  : 0;
              return (
                <>
                  <Flex>
                    <Flex className="header">{"순도"}</Flex>
                    <Flex className="header">{"총 실중량"}</Flex>
                    <Flex className="header">{"총 감정 실중량"}</Flex>
                    <Flex className="header">{"환산율"}</Flex>
                    <Flex className="header">{"출고가능 환산율"}</Flex>
                  </Flex>
                  <Flex>
                    <Flex className="data">{item.label}</Flex>
                    <Flex className="data">
                      <NumericTextBox
                        value={value}
                        onChange={(e) => {
                          setRefineList(
                            refineList.map((item, inputIndex) => {
                              return inputIndex === index
                                ? {
                                    ...item,
                                    weightGram: e.value,
                                  }
                                : { ...item };
                            })
                          );
                          setValue(e.value);
                        }}
                        width={80}
                        size={"small"}
                        fillMode={"outline"}
                        spinners={false}
                        style={{ verticalAlign: "sub" }}
                        inputStyle={{ backgroundColor: "white", height: 18 }}
                      />
                    </Flex>
                    <Flex className="data" style={{ justifyContent: "right" }}>
                      {item.value}
                    </Flex>
                    <Flex className="data" style={{ justifyContent: "center" }}>
                      {rate}
                    </Flex>
                    <Flex
                      className="data"
                      style={{
                        justifyContent: "center",
                      }}>{`${item.outMinRate} ~ ${item.outMaxRate}`}</Flex>
                  </Flex>
                </>
              );
            })}
          </Flex>
        </StyledTable>
        <AdminConfirmButton
          rootClassName={classes.confirmRoot}
          btnClassName={classes.confirmButton}
          popupMessage="정련하시겠습니까?"
          label="정련 요청"
          popupLabel="정련 요청 확인"
          callback={handleClickSave}
        />
      </div>
    );
  }
);
//입고수정 모달
const ReceivingRefineModify = withProgressBar(
  ({ progressBar, id, fetchList, list }) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const [history, setHistory] = useState([]);
    const selectItem = list.find((item) => item.id === id);
    useEffect(() => {
      progressBar.on();
      APIS.getInventoryRefineInHistory(id)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            const copyData = [
              ...data.map((item) => {
                return { ...item, weightGram: 0 };
              }),
            ];
            copyData.push({});
            setHistory(copyData);
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    }, []);

    const handleClickEdit = (adminPassword) => {
      let last = history[history.length - 1];
      if (!last.weightGram || last.weightGram === 0) {
        dispatch(actionError("중량값을 입력하여 주세요"));
      } else if (!last.managerMemo || last.managerMemo === "") {
        dispatch(actionError("메모를 입력하여 주세요"));
      } else {
        const data = {
          id: id,
          gram: last.weightGram,
          memo: last.managerMemo,
        };
        data["managerPassword"] = adminPassword;
        progressBar.on();
        APIS.patchInventoryRefineIn(data)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "수정이 완료되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
              dispatch(closeModal());
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(progressBar.off);
      }
    };

    return (
      <div>
        <StyledTable>
          <Flex row>
            <Flex className="header" style={{ width: 152 }}>
              실중량
            </Flex>
            <Flex className="header" style={{ width: 152 }}>
              출고최소중량
            </Flex>
            <Flex className="header" style={{ width: 152 }}>
              출고최대중량
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(selectItem.inputWeightGram)}g`}
            </Flex>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(
                Math.floor(
                  selectItem.inputWeightGram * selectItem.stockMinRate * 100
                ) / 100
              )}g`}
              {`(${selectItem.stockMinRate * 100}%)`}
            </Flex>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(
                Math.floor(
                  selectItem.inputWeightGram * selectItem.stockMaxRate * 100
                ) / 100
              )}g`}
              {`(${selectItem.stockMaxRate * 100}%)`}
            </Flex>
          </Flex>
        </StyledTable>
        <GridTable
          className="grid"
          data={history}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
          }}>
          <GridColumn
            className="td-c"
            title="날짜"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {formatTime(dataItem["stockedAt"]) || "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="수정중량"
            cell={(props) => {
              const [value, setValue] = useState();
              return (
                <>
                  {props.dataItem.stockedWeightGram ||
                  props.dataItem.stockedWeightGram === 0 ? (
                    <NumberCell {...props}>
                      {numberWithCommas(props.dataItem.stockedWeightGram)}
                    </NumberCell>
                  ) : (
                    <NumericTextBox
                      value={value}
                      onChange={(e) => {
                        setValue(e.value);
                        history[props.dataIndex]["weightGram"] = e.value;
                      }}
                      width={80}
                      size={"small"}
                      fillMode={"outline"}
                      spinners={false}
                      style={{ verticalAlign: "sub" }}
                      inputStyle={{ backgroundColor: "white", height: 20 }}
                    />
                  )}
                </>
              );
            }}
          />
          <GridColumn
            className="td-r"
            title="관리자메모"
            cell={(props) => {
              const { dataItem } = props;
              const [value, setValue] = useState("");
              return (
                <>
                  {dataItem["stockedAt"] ? (
                    dataItem["managerMemo"] ? (
                      <td> {dataItem["managerMemo"]}</td>
                    ) : (
                      <td className="td-c">-</td>
                    )
                  ) : (
                    <td className="td-c">
                      <input
                        value={value}
                        style={{ width: 220 }}
                        onChange={(e) => {
                          history[props.dataIndex]["managerMemo"] =
                            e.target.value;
                          setValue(e.target.value);
                        }}
                      />
                    </td>
                  )}
                </>
              );
            }}
          />
          <GridColumn
            className="td-r"
            title="관리자"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {dataItem["managerName"] ? dataItem["managerName"] : <></>}
                </td>
              );
            }}
          />
        </GridTable>
        <AdminConfirmButton
          rootClassName={classes.confirmRoot}
          btnClassName={classes.confirmButton}
          popupMessage="입고 수정하시겠습니까?"
          label="입고수정"
          popupLabel="입고 수정"
          callback={handleClickEdit}
        />
      </div>
    );
  }
);

//입고 모달
const ReceivingRefineModal = withProgressBar(
  ({ progressBar, id, fetchList, comma, list }) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const [history, setHistory] = useState([]);
    const [stockStatus, setStockStatus] = useState("");
    const selectItem = list.find((item) => item.id === id);

    useEffect(() => {
      progressBar.on();
      APIS.getInventoryRefineInHistory(id)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            const copyData = [
              ...data.map((item) => {
                return { ...item, weightGram: 0 };
              }),
            ];
            copyData.push({});
            setHistory(copyData);
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    }, []);

    const handleClickSave = (adminPassword) => {
      let last = history[history.length - 1];
      if (!last.weightGram || last.weightGram === 0) {
        dispatch(actionError("중량값을 입력하여 주세요"));
      } else if (!stockStatus || stockStatus === "") {
        dispatch(actionError("상태값을 입력하여 주세요"));
      } else {
        const data = {
          id: id,
          stockedWeightGram: last.weightGram ? last.weightGram : 0,
          refineStatus: stockStatus,
        };
        data["managerPassword"] = adminPassword;
        progressBar.on();
        APIS.postInventoryRefineIn(data)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "저장이 완료되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
              dispatch(closeModal());
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(progressBar.off);
      }
    };

    return (
      <div>
        <StyledTable>
          <Flex row>
            <Flex className="header" style={{ width: 151 }}>
              실중량
            </Flex>
            <Flex className="header" style={{ width: 151 }}>
              출고최소중량
            </Flex>
            <Flex className="header" style={{ width: 151 }}>
              출고최대중량
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(selectItem.inputWeightGram)}g`}
            </Flex>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(
                Math.floor(
                  selectItem.inputWeightGram * selectItem.stockMinRate * 100
                ) / 100
              )}g`}
              {`(${selectItem.stockMinRate * 100}%)`}
            </Flex>
            <Flex className="data" style={{ justifyContent: "center" }}>
              {`${numberWithCommas(
                Math.floor(
                  selectItem.inputWeightGram * selectItem.stockMaxRate * 100
                ) / 100
              )}g`}
              {`(${selectItem.stockMaxRate * 100}%)`}
            </Flex>
          </Flex>
        </StyledTable>
        <GridTable
          className="grid"
          data={history}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
          }}>
          <GridColumn
            className="td-c"
            title="날짜"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {formatTime(dataItem["stockedAt"]) || "-"}
                </td>
              );
            }}
          />
          <GridColumn
            title="입고중량"
            width={151}
            cell={(props) => {
              const stockedWeightGram = props.dataItem["weightGram"];
              const [value, setValue] = useState(stockedWeightGram || 0);
              useEffect(() => {
                setValue(stockedWeightGram);
              }, [stockedWeightGram]);
              return (
                <CenterCell {...props}>
                  {props.dataItem.stockedWeightGram ||
                  props.dataItem.stockedWeightGram === 0 ? (
                    numberWithCommas(props.dataItem.stockedWeightGram)
                  ) : (
                    <input
                      value={value}
                      style={{ textAlign: "right", width: 70 }}
                      onChange={(e) => {
                        history[props.dataIndex]["weightGram"] = comma(
                          e.target.value
                        );
                        setValue(comma(e.target.value));
                      }}
                    />
                  )}
                </CenterCell>
              );
            }}
          />
          <GridColumn
            className="td-r"
            title="입고상태"
            width={151}
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td className="td-c">
                  {dataItem["stockedStatus"] ? (
                    dataItem["stockedStatus"]
                  ) : (
                    <DropDownList
                      options={[
                        { label: "부분입고", value: "STOCKED_PART" },
                        { label: "입고완료", value: "STOCKED_COMPLETE" },
                      ]}
                      selected={stockStatus}
                      onChange={(value) => setStockStatus(value)}
                      style={{ width: 100 }}
                    />
                  )}
                </td>
              );
            }}
          />
        </GridTable>
        <AdminConfirmButton
          rootClassName={classes.confirmRoot}
          btnClassName={classes.confirmButton}
          popupMessage="입고하시겠습니까?"
          label="입고"
          popupLabel="입고 확인"
          callback={handleClickSave}
        />
      </div>
    );
  }
);

const ReceivingRefineChargeModal = withProgressBar(
  ({ progressBar, id, fetchList, comma }) => {
    const dispatch = useDispatch();
    const [purityPercent, setPurityPercent] = useState();
    const [electrolyticPolarGram, setElectrolyticPolarGram] = useState();

    const handleClickSave = () => {
      if (
        purityPercent === "undefined" ||
        electrolyticPolarGram === "undefined" ||
        !purityPercent ||
        !electrolyticPolarGram
      ) {
        dispatch(actionError("값을 입력하여 주세요"));
      } else {
        const data = {
          id: id,
          inputPurityPct: purityPercent,
          electrolyticPolarGram: electrolyticPolarGram,
        };
        progressBar.on();
        APIS.postInventoryRefineInCharge(data)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "저장이 완료되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
              dispatch(closeModal());
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(progressBar.off);
      }
    };
    return (
      <div style={{ width: 200 }}>
        <SmallTitle>책임중량</SmallTitle>
        <Flex row style={{ marginTop: 15, justifyContent: "space-between" }}>
          <SmallTitle>순도 :</SmallTitle>
          <NumericTextBox
            value={purityPercent}
            onChange={(e) => {
              setPurityPercent(e.value);
            }}
            width={90}
            size={"small"}
            fillMode={"outline"}
            spinners={false}
            max={100}
          />
        </Flex>
        <Flex row style={{ marginTop: 10, justifyContent: "space-between" }}>
          <SmallTitle>전해양극중량 :</SmallTitle>
          <input
            value={electrolyticPolarGram}
            onChange={(e) => {
              setElectrolyticPolarGram(comma(e.target.value));
            }}
            style={{
              width: 90,
              borderColor: "rgba(66, 66, 66, 0.5)",
              borderWidth: "thin",
              borderRadius: 4,
              padding: "2px 8px",
            }}
          />
        </Flex>
        <Flex style={{ alignItems: "center" }}>
          <KendoButton
            onClick={() => {
              handleClickSave();
            }}
            style={{ marginTop: 35 }}>
            저장
          </KendoButton>
        </Flex>
      </div>
    );
  }
);

const EtcRefineModal = withProgressBar(({ progressBar, comma }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [etcList, setEtcList] = useState([]);
  const [etcTypeList, setEtcTypeList] = useState([]);
  const [stockedAt, setStockedAt] = useState(formatTime()); //날짜
  const [assetType, setAssetType] = useState(""); //자산타입
  const [etcType, setEtcType] = useState([]); //타입
  const [weightGram, setWeightGram] = useState(""); //중량
  const [memo, setMemo] = useState(""); //메모
  const [etcListPage, setEtcListPage] = useState({
    page: 0,
    size: 10,
  });

  useEffect(() => {
    progressBar.on();
    APIS.getInventoryRefineEtcType()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const newData = data.map((item) => {
            return { label: item.description, value: item.nonRequestType };
          });
          setEtcTypeList(newData);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  }, []);

  useEffect(() => {
    const param = {
      page: etcListPage.page,
      size: etcListPage.size,
    };
    progressBar.on();
    APIS.getInventoryRefineEtc(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setEtcListPage({
            page: data.number,
            size: data.size,
            total: data.totalElements,
          });
          setEtcList(data.content);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  }, [etcListPage.page, etcListPage.size]);

  const handleClickSave = (adminPassword) => {
    if (weightGram === 0) {
      dispatch(actionError("중량값을 입력하여 주세요"));
    } else if (
      stockedAt === "" ||
      assetType === "" ||
      etcType.length === 0 ||
      weightGram === "" ||
      memo === "" ||
      !stockedAt ||
      !assetType ||
      !etcType ||
      !weightGram ||
      !memo
    ) {
      dispatch(actionError("값을 입력하여 주세요"));
    } else {
      const data = {
        stockedDate: stockedAt,
        assetType: assetType,
        stockedWeightGram: weightGram,
        nonRequestType: etcType,
        memo: memo,
      };
      data["managerPassword"] = adminPassword;
      progressBar.on();
      APIS.postInventoryRefineEtc(data)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            dispatch(
              actionOpen("저장이 완료되었습니다.", null, null, null, true)
            );
            dispatch(closeModal());
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    }
  };
  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    setEtcListPage({ page: skip / take, size: take, total: etcListPage.total });
  };

  return (
    <div>
      <GridTable
        className="grid"
        data={etcList}
        skip={etcListPage.page * etcListPage.size}
        take={etcListPage.size}
        total={etcListPage.total}
        pageable
        onPageChange={onPageChange}
        scrollable
        style={{ height: 348 }}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
        }}>
        <GridColumn
          width={130}
          title="날짜"
          cell={(props) => {
            const { dataItem } = props;
            return (
              <td className="td-c">
                {formatTime(dataItem["refineStockHistory"]?.[0]?.stockedAt)}
              </td>
            );
          }}
        />
        <GridColumn
          width={80}
          title="자산타입"
          className="td-c"
          field={"type"}
        />
        <GridColumn
          width={130}
          className="td-c"
          title="타입"
          field={"nonRequestType"}
        />
        <GridColumn
          title="중량"
          width={120}
          cell={(props) => {
            return (
              <NumberCell {...props}>
                {numberWithCommas(props.dataItem.stockedWeightGram)}
              </NumberCell>
            );
          }}
        />
        <GridColumn
          title="메모"
          width={420}
          cell={(props) => {
            return (
              <td className="td-l">
                {props.dataItem["refineStockHistory"]?.[0]?.managerMemo}
              </td>
            );
          }}
        />
      </GridTable>
      <Flex row style={{ marginTop: 15 }}>
        <Flex row>
          <StyledTable>
            <Flex row>
              <Flex className="header" style={{ width: 151 }}>
                날짜
              </Flex>
              <Flex className="header" style={{ width: 101 }}>
                자산타입
              </Flex>
              <Flex className="header" style={{ width: 141 }}>
                타입
              </Flex>
              <Flex className="header" style={{ width: 101 }}>
                중량
              </Flex>
              <Flex className="header" style={{ width: 291 }}>
                메모
              </Flex>
              <Flex className="header" style={{ width: 121 }} />
            </Flex>
            <Flex row>
              <Flex className="data">
                <input
                  type="date"
                  value={stockedAt}
                  onChange={(e) => {
                    setStockedAt(e.target.value);
                  }}
                  style={{ width: 130 }}
                />
              </Flex>
              <Flex className="data">
                <DropDownList
                  options={[
                    { label: "금", value: "GOLD" },
                    { label: "은", value: "SILVER" },
                  ]}
                  selected={assetType}
                  onChange={(value) => setAssetType(value)}
                  style={{ width: 80 }}
                />
              </Flex>
              <Flex className="data">
                <DropDownList
                  options={etcTypeList}
                  selected={etcType}
                  onChange={(value) => setEtcType(value)}
                  style={{ width: 120 }}
                />
              </Flex>
              <Flex className="data">
                <TextInput
                  value={weightGram}
                  onChange={(value) => {
                    setWeightGram(comma(value));
                  }}
                  style={{ textAlign: "right", width: 80 }}
                />
              </Flex>
              <Flex className="data">
                <TextInput
                  value={memo}
                  onChange={(value) => {
                    setMemo(value);
                  }}
                  style={{ textAlign: "left", width: 270 }}
                />
              </Flex>
              <Flex className="data">
                <AdminConfirmButton
                  rootClassName={classes.confirmRoot}
                  btnClassName={classes.confirmButton}
                  popupMessage="입고하시겠습니까?"
                  label="기타입고"
                  popupLabel="입고 확인"
                  callback={handleClickSave}
                />
              </Flex>
            </Flex>
          </StyledTable>
        </Flex>
      </Flex>
    </div>
  );
});

const InventoryStatusContainer = styled(Flex)`
  flex: 1;
  padding: 10px;
  height: 100vh;
  .k-animation-container-relative {
    display: block;
  }
  .inventory-status-grid {
    margin-top: 10px;
  }
`;

const InventoryRefineContainer = styled(Flex)`
  margin-top: 5px;
  .summary::-webkit-scrollbar {
    height: 6px;
  }
  .summary::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .summary::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: gray;
  }
  .summary::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  .summary {
    max-width: 1200px;
    overflow-x: scroll;
    margin-left: 5px;
    .header {
      width: 100px;
    }
    .data {
      min-width: 100px;
      justify-content: right;
      padding: 0 10px;
    }
  }

  // .grid {
  //   margin-top: 10px;
  // }
  .title {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 14px;
    align-self: center;
    font-weight: bold;
  }
  .click-cell {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;

const PopupContainer = styled(Flex)`
  .title {
    background: #f2f4f6;
    align-items: center;
    padding: 10px 0;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e2e5e8;
  }

  .content {
    padding: 10px;
    > div:not(:last-child) {
      margin-bottom: 10px;
    }

    .header {
      width: 80px;
      display: flex;
      align-items: center;
    }

    .radio {
      flex: 1;
      align-items: center;
      label {
        flex: 1;
        font-size: 18px;
      }
    }

    .button-section {
      button {
        flex: 1;
      }
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

const RefineStatus = styled(Flex)`
  width: 100%;
  position: sticky;
  top: 10px;
  left: 10px;
  margin-bottom: 10px;
`;

export default InventoryRefine;

const useStyle = makeStyles({
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
  },
  confirmButton: {
    minWidth: 70,
    height: 33,
    width: 100,
    marginLeft: 0,
  },
});
