import {
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import DateRange from "../../components/date-range/DateRange";
import Flex from "../../components/flex/Flex";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import colors from "../../libs/colors";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../libs/utils";
import { objToQueryStr, formatTime } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Button from "../../components/button/Button";
import KendoButton from "../../components/button/KendoButton";
import { saveAs } from "file-saver";

const TABLE_CELLS = [
  {
    key: "salesReportType",
    type: "string",
    logicFunction: function (string) {
      return string
        .replace("SELF", "자진발행")
        .replace("TAX_BILL", "세금계산서")
        .replace("CASH", "현금영수증")
        .replace("TOTAL", "합계");
    },
  },
  { key: "totalSell", type: "number" },
  { key: "totalBuy", type: "number" },
  { key: "totalGoods", type: "number" },
  { key: "totalStorage", type: "number" },
  { key: "totalSafeTradeBuy", type: "number" },
  { key: "totalSafeTradeSell", type: "number" },
  { key: "totalSafeTradeEtc", type: "number" },
  { key: "totalWithdraw", type: "number" },
  { key: "supplyPrice", type: "number" },
  { key: "vat", type: "number" },
  { key: "totalFee", type: "number" },
  { key: "appraisalSupply", type: "number" },
  { key: "appraisalVat", type: "number" },
  { key: "totalAppraisal", type: "number" },
];

export default function SettlementHistory({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { replace } = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [filterState, setFilterState] = useState("");
  const [startDate, setstartDate] = useState(
    formatTime(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [salesStatus, setSalesStatus] = useState([]); // 매출 현황 목록
  const [totalCount, setTotalCount] = useState(0);
  const radioButtonData = [
    { label: "전체", value: "" },
    { label: "미완료", value: "NEW" },
    { label: "발행완료", value: "DONE" },
  ];
  const reformData = (data) => {
    const copyData = [...data] || [];
    const dayList = copyData?.map((item) => {
      return { day: item["date"] };
    });
    const deduplicationDay = dayList?.filter((dayName, idx, arr) => {
      return arr.findIndex((item) => item.day === dayName.day) === idx;
    });
    let parseData = [];
    let arrayData = [];

    deduplicationDay?.forEach((day) => {
      // day 날짜 필터링
      copyData?.forEach((item) => {
        if (item["date"] === day.day) {
          arrayData.push(item);
        }
      });
      parseData.push({ day: day.day, detail: arrayData });
      arrayData = [];
    });

    parseData?.map((item) => {
      if (item.detail.length > 1) {
        item.detail.push(
          item.detail?.reduce(
            (acc, curr) => {
              acc.supplyPrice = (acc.supplyPrice || 0) + curr.supplyPrice;
              acc.totalBuy = (acc.totalBuy || 0) + curr.totalBuy;
              acc.totalFee = (acc.totalFee || 0) + curr.totalFee;
              acc.totalGoods = (acc.totalGoods || 0) + curr.totalGoods;
              acc.totalSafeTradeBuy =
                (acc.totalSafeTradeBuy || 0) + curr.totalSafeTradeBuy;
              acc.totalSafeTradeSell =
                (acc.totalSafeTradeSell || 0) + curr.totalSafeTradeSell;
              acc.totalSafeTradeEtc =
                (acc.totalSafeTradeEtc || 0) + curr.totalSafeTradeEtc;
              acc.totalSell = (acc.totalSell || 0) + curr.totalSell;
              acc.totalWithdraw = (acc.totalWithdraw || 0) + curr.totalWithdraw;
              acc.vat = (acc.vat || 0) + curr.vat;
              return acc;
            },
            {
              date: null,
              id: "",
              salesReportAt: null,
              salesReportType: "TOTAL",
              settledAt: "",
              status: "",
            }
          )
        );
      }
    });
    return parseData;
  };

  const fetchList = () => {
    const radioStatus = state.radioStatus === "" ? null : state.radioStatus;
    const param = {
      from: startDate,
      to: endDate,
      status: radioStatus,
    };
    const startDay = new Date(startDate);
    const endDay = new Date(endDate);
    const dateDiff = Math.ceil(
      (endDay.getTime() - startDay.getTime()) / (1000 * 3600 * 24)
    );
    if (!startDate || !endDate) {
      dispatch(actionError("날짜를 선택해주세요."));
    } else if (dateDiff > 92) {
      dispatch(actionError("검색 가능 기간은 최대 3개월입니다."));
    } else {
      dispatch(loadingStart);
      APIS.getSalesStatusList(objToQueryStr(param))
        .then(({ data: { data, message, success } }) => {
          if (success) {
            setSalesStatus(reformData(data));
            setTotalCount(reformData(data).length);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const onClickExcelDownload = () => {
    if (salesStatus.length === 0) {
      dispatch(
        actionError(`조회된 리스트가 없습니다. 
      리스트를 조회해주세요`)
      );
    } else {
      const radioStatus = state.radioStatus === "" ? null : state.radioStatus;
      const param = {
        from: startDate,
        to: endDate,
        status: radioStatus,
      };

      for (let key in param) {
        const value = param[key];

        if (typeof value === "string" && !value) {
          delete param[key];
        }
      }
      dispatch(loadingStart);
      APIS.getSalesStatusListExcel(objToQueryStr(param))
        .then((response) => {
          const blob = new Blob([response.data]);
          saveAs(
            blob,
            `정산현황_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
          );
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    handleValueChange("radioStatus", "");
  }, []);

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
      });
    }
  }, [state.tab, startDate, endDate, state.radioStatus]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "매출현황", value: "1" },
          // { label: "매입현황", value: "2" },
        ]}
      />
      <Flex row className={classes.toolbar}>
        <Flex style={{ display: "-webkit-box" }}>
          <DateRange
            startDate={setstartDate}
            endDate={setEndDate}
            period={["week", "month", "month3"]}
          />
          <RadioGroup
            style={{ marginLeft: 40 }}
            row
            value={filterState}
            onChange={(e) => {
              handleValueChange("radioStatus", e.target.value);
              setFilterState(e.target.value);
            }}
          >
            {radioButtonData?.map((radio, index) => {
              return (
                <FormControlLabel
                  key={index.toString()}
                  value={radio.value}
                  control={
                    <Radio color="default" style={{ color: colors.black }} />
                  }
                  label={radio.label}
                />
              );
            })}
          </RadioGroup>
          <Button
            style={{ marginLeft: 20 }}
            label="검색"
            className={classes.searchButton}
            onClick={fetchList}
          />
        </Flex>
        <Flex>
          <KendoButton
            style={{ width: 60, height: 35 }}
            onClick={() => {
              onClickExcelDownload();
            }}
          >
            Excel
          </KendoButton>
        </Flex>
      </Flex>
      <Flex row className={classes.tableHeader}>
        <Flex>
          <Flex row className={"row-center"}>
            <Text font={fonts.notoSansKRBold}>목록 검색결과 {totalCount}</Text>
          </Flex>
        </Flex>
      </Flex>
      <SalesStatusTable
        data={salesStatus?.map((row, rowIndex) => {
          let status = "";
          let salesReportType = "";
          switch (row.detail.status) {
            case "NEW":
              status = "미완료";
              break;
            case "DONE":
              status = "발행완료";
              break;
          }
          switch (row.detail.salesReportType) {
            case "SELF":
              salesReportType = "자진발행";
              break;
            case "CASH":
              salesReportType = "현금영수증";
              break;
            case "TOTAL":
              salesReportType = "합계";
              break;
          }
          return {
            ...row,
            status,
            salesReportType,
          };
        })}
        fetchList={fetchList}
      />
    </>
  );
}

const SalesStatusTable = ({ data, fetchList }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const handleClickSave = (adminPassword, id) => {
    const param = {
      idList: [id],
      status: "DONE",
    };
    param["password"] = adminPassword;

    dispatch(loadingStart);
    APIS.getSalesStatusChange(param)
      .then(({ data: { success, message } }) => {
        if (!success) {
          dispatch(actionOpen(message, null, null, null, true));
        } else {
          dispatch(
            actionOpen("저장이 완료되었습니다.", null, null, null, true)
          );
          fetchList();
        }
      })
      .finally(() => dispatch(loadingEnd));
    // }
  };
  //state.tab으로 매출현황, 매입현황 내용 구분지어 주기
  const headerRows = [
    [
      { label: "날짜", rowSpan: 2 },
      { label: "구분", rowSpan: 2 },
      { label: "매도", rowSpan: 2 },
      { label: "매수", rowSpan: 2 },
      { label: "실물인출", rowSpan: 2 },
      { label: "보관료", rowSpan: 2 },
      { label: "안심직거래", colSpan: 3 },
      { label: "출금", rowSpan: 2 },
      { label: "총 매출 수수료", colSpan: 3 },
      { label: "감정평가", colSpan: 3 },
      { label: "처리일시", rowSpan: 2 },
      { label: "처리완료", rowSpan: 2 },
      { label: "확인", rowSpan: 2, width: 50 },
    ],
    [
      { label: "구매" },
      { label: "판매" },
      { label: "기타" },
      { label: "공급가" },
      { label: "부가세" },
      { label: "합계" },
      { label: "공급가" },
      { label: "부가세" },
      { label: "합계" },
    ],
  ];

  return (
    <Flex style={{ margin: "0 50px 0 50px" }}>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            {headerRows?.map((row, index) => {
              return (
                <TableRow key={index}>
                  {row.map(
                    ({ label, rowSpan, colSpan, width, render }, index) => {
                      console.log(label, rowSpan, colSpan, width, render);
                      return (
                        <TableCell
                          key={index}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                          width={width}
                        >
                          {render || label}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {data?.map((row, index) => {
              console.log(row);
              return (
                <TableRow
                  key={index}
                  className={
                    row.detail["salesReportType"] === "합계"
                      ? classes.total
                      : row.detail["date"]?.split("-")[2] % 2 !== 0
                      ? classes.even
                      : classes.odd
                  }
                  // style={{ cursor: "pointer" }}
                >
                  <TableCell
                    style={{
                      rowSpan: `${row.detail.length + 1}`,
                      borderRight: "1px solid #e0e0e0",
                    }}
                    align={"center"}
                  >
                    {row.day}
                  </TableCell>
                  {TABLE_CELLS.map((table_cell) => {
                    const { key, type, logicFunction } = table_cell;
                    return (
                      <TableCell style={{ padding: "0px" }} key={key}>
                        <Table style={{ borderRight: "none" }}>
                          {row?.detail.map((detail, index) => {
                            return (
                              <TableRow key={detail}>
                                <TableCell
                                  align={"center"}
                                  style={{
                                    borderBottom: "none",
                                    height: "46px",
                                    borderRight: "none",
                                  }}
                                >
                                  {type === "number"
                                    ? numberWithCommas(detail[key])
                                    : logicFunction(detail[key])}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </TableCell>
                    );
                  })}
                  <TableCell style={{ padding: "0px" }}>
                    <Table style={{ borderRight: "none" }}>
                      {row?.detail.map((detail, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              align={"center"}
                              style={{
                                borderBottom: "none",
                                height: "46px",
                                borderRight: "none",
                              }}
                            >
                              {formatTime(
                                detail.settledAt,
                                "YYYY.MM.DD HH:mm:SS"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </TableCell>
                  <TableCell style={{ padding: "0px" }}>
                    <Table style={{ borderRight: "none", height: "44px" }}>
                      {row?.detail.map((detail, index) => {
                        return detail.salesReportType === "SELF" ? (
                          <TableRow key={index}>
                            <TableCell
                              align={"center"}
                              style={{
                                height: "46px",
                                borderRight: "none",
                              }}
                            >
                              {detail.status
                                .replace("NEW", "미완료")
                                .replace("DONE", "발행완료")}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={index}>
                            <TableCell
                              align={"center"}
                              style={{
                                height: "46px",
                                borderRight: "none",
                              }}
                            />
                          </TableRow>
                        );
                      })}
                    </Table>
                  </TableCell>
                  <TableCell style={{ padding: "0px" }}>
                    <Table style={{ borderRight: "none" }}>
                      {row?.detail.map((data, index) => {
                        return data.status === "NEW" &&
                          data.salesReportType === "SELF" ? (
                          <TableRow key={index}>
                            <TableCell
                              align={"center"}
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                height: "46px",
                              }}
                            >
                              {isKorda && (
                                <AdminConfirmButton
                                  rootClassName={classes.confirmRoot}
                                  btnClassName={classes.confirmButton}
                                  popupMessage="변경하시겠습니까?"
                                  label="확인"
                                  popupLabel="발행여부 상태 변경"
                                  selectItem={data.id}
                                  callback={handleClickSave}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell
                              align={"center"}
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                                height: "46px",
                              }}
                            />
                          </TableRow>
                        );
                      })}
                    </Table>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "10px 50px",
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
  },
  confirmButton: {
    minWidth: 70,
    height: 33,
    width: 40,
    marginLeft: 0,
  },
  toolbar: {
    margin: "50px 50px 0px 50px",
    justifyContent: "space-between",
  },
  total: {
    background: "darkgray",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  searchButton: {
    height: 35,
    marginRight: 40,
  },
});
